import BVStyleguide from '@bv/styleguide';
import { useForm } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useMutation } from '@tanstack/react-query';
import { CREATE_REVIEW } from '../../../../consts';
import { CreateReviewDto, createReviewService, Rating } from '../../../../services/review.service';
import { useEffect, useState } from 'react';
import { ReviewStar } from '@public-review/components/ReviewStar';
import { useNavigate, useSearchParams } from 'react-router-dom';
import * as singleSpa from 'single-spa';
import BVUtils from '@bv/utils';
import { Form as DSForm, FormInput, EInput, Button } from '@eyecarehealth/ds-aquilae-react';

const formSchema = z.object({
  comment: z.string().min(1).max(400),
});

export function Form() {
  const methods = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
  });
  const [generalRating, setGeneralRating] = useState<Rating | null>(null);
  const [attendanceRating, setAttendanceRating] = useState<Rating | null>(null);
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const couponId = searchParams.get('couponId');

  useEffect(() => {
    if (!couponId) {
      setTimeout(() => {
        BVUtils.toastHandler({ type: 'error', message: 'Não é possível avaliar essa ótica.' });
      }, 300);
      setTimeout(() => {
        singleSpa.navigateToUrl('/');
      }, 3000);
    }
  }, []);

  const { mutate: createReview, isPending } = useMutation({
    mutationKey: [CREATE_REVIEW],
    mutationFn: async (data: z.infer<typeof formSchema>) => {
      const objToSend: CreateReviewDto = {
        ratings: [generalRating, attendanceRating],
        couponId,
        comment: data.comment,
      };

      return createReviewService(objToSend);
    },
    onSuccess: () => {
      navigate('/sucesso');
    },
  });

  return (
    <div className="mt-6 space-y-6">
      <div>
        <p className="mb-1 text-sm font-medium">Avalie a ótica de acordo com sua experiência geral:</p>
        <div className="flex gap-2">
          {Array.from({ length: 5 }).map((_, index) => (
            <button key={index} onClick={() => setGeneralRating({ aspect: 'place', stars: index + 1 })}>
              <ReviewStar color={generalRating?.stars >= index + 1 ? '#F5BF00' : '#D9D9D9'} />
            </button>
          ))}
        </div>
      </div>

      <div>
        <p className="mb-1 text-sm font-medium">Como foi o atendimento da ótica para você?</p>
        <div className="flex gap-2">
          {Array.from({ length: 5 }).map((_, index) => (
            <button key={index} onClick={() => setAttendanceRating({ aspect: 'people', stars: index + 1 })}>
              <ReviewStar color={attendanceRating?.stars >= index + 1 ? '#F5BF00' : '#D9D9D9'} />
            </button>
          ))}
        </div>
      </div>
      <DSForm ctx={methods} onSubmit={createReview}>
        <FormInput
          label="Conte-nos mais sobre o que você achou do atendimento e da qualidade dos produtos."
          description="Máximo de 400 caracteres."
          name="comment"
          type={EInput.TEXTAREA}
        />
        <Button type="submit" loading={isPending}>
          Enviar
        </Button>
      </DSForm>
    </div>
  );
}
