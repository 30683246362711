import axios from 'axios';
import BVUtils from '@bv/utils';

export const api = axios.create({
  baseURL: process.env.BV_API_URL,
});

api.interceptors.request.use(function (config) {
  const ACCESS_TOKEN: string | null = BVUtils.cookies.getAccessToken();

  if (ACCESS_TOKEN) {
    Object.assign(config.headers, {
      Authorization: `Bearer ${ACCESS_TOKEN}`,
    });
  }

  return config;
});

api.interceptors.response.use(
  function (response) {
    return response.data;
  },
  function (err) {
    // if (err.response && [401, 403].includes(+err.response.status)) {
    //   BVUtilsOld.cookiesHelper.clearAuthCookies();
    //   window.localStorage.clear();
    //   window.location.href = '/login';
    // }

    if (err?.response?.data?.messages) {
      BVUtils.request.requestErrorToastHandler(err.response.data.messages);
      return Promise.reject(err.response.data.messages);
    }

    if (err?.response?.data?.message) {
      BVUtils.request.requestErrorToastHandler(err.response.data.message);
      return Promise.reject(err.response.data.message);
    }

    return Promise.reject('Não foi possível realizar essa ação, caso o problema persista, contate o suporte.');
  },
);
