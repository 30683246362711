import { beneficioVisaoLogoAsset } from '@public-review/assets';
import { Outlet } from 'react-router-dom';

export function Layout() {
  return (
    <div className="h-screen w-screen bg-white">
      <div className="mb-6 flex justify-center border-b border-gray-400 py-4">
        <img src={beneficioVisaoLogoAsset} alt="Logo Beneficio Visao" />
      </div>
      <Outlet />
    </div>
  );
}
