import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import Rating from './pages/Rating';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import Success from './pages/Success';
import { Layout } from './components/Layout';

//trigger pipeline4

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const router = createBrowserRouter(
  [
    {
      path: '/',
      element: <Layout />,
      children: [
        {
          path: '/',
          element: <Rating />,
        },
        { path: '/sucesso', element: <Success /> },
      ],
    },
  ],
  { basename: '/avaliar' },
);

export default function App(props) {
  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={router} />
    </QueryClientProvider>
  );
}
